import CryptoJS from 'crypto-js';
import { DateTime } from 'luxon';
import { ResponseHttp } from './responseHttp';

export const encriptacion = (data) => {
  return CryptoJS.AES.encrypt(
    data,
    process.env.REACT_APP_DATA_SECRET
  ).toString();
};

export const decodificacion = (data) => {
  const decode = CryptoJS.AES.decrypt(data, process.env.REACT_APP_DATA_SECRET);
  return decode.toString(CryptoJS.enc.Utf8);
};

export const decodificacionObject = (data) => {
  if (data !== null) {
    const decode = CryptoJS.AES.decrypt(
      data,
      process.env.REACT_APP_DATA_SECRET
    );

    return decode.toString(CryptoJS.enc.Utf8) !== ''
      ? JSON.parse(decode.toString(CryptoJS.enc.Utf8))
      : null;
  }
  return null;
};

export const decodificacionSecret = () => {
  const decode = CryptoJS.enc.Base64.parse(process.env.REACT_APP_OAUTH2_SECRET);
  return decode.toString(CryptoJS.enc.Utf8);
};

export const fechaActual = () => {
  const fecha = DateTime.now().setZone('America/El_Salvador');
  const tiempo = fecha.toISOTime().split('-');
  const fechaFormat = `${fecha.toISODate()}T${tiempo[0]}`;
  return fechaFormat;
};

export const apiFormatDate = (date) => {
  // Fecha actual
  const localDate = new Date(date);

  // Ajuste de diferencia horaria general
  localDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());

  // Formato para mostrar
  const dateJson = localDate.toJSON();
  const formatDateTime = dateJson.split('.');
  return `${formatDateTime[0]}Z`;
};

// Descargar archivos
export const descargarArchivos = (formato, archivo, nombre, extension) => {
  const dataStr = formato + encodeURIComponent(archivo);
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', `${nombre}.${extension}`);
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const manejoErrores = (error) => {
  let tipoError = '';
  let respuesta = { message: '', status: '', infoError: '' };

  if (error.response) {
    tipoError = ResponseHttp(error.response.status);
    respuesta = {
      message: error,
      status: error.response.status,
      infoError: tipoError,
      data: error.response.data,
    };
  }

  return respuesta;
};

export const formatDateView = (fecha) => {
  return `${fecha.getFullYear()}-${fecha.getMonth() + 1}-${fecha.getDate()}`;
};

export const _removeDuplicatesArray = (arr) => {
  return [...new Set(arr)];
};

export const _getFractionPoint = (num) => {
  let fractionPoints = 0;
  if (isNaN(num)) return fractionPoints;

  if (typeof num !== 'number') {
    num = Number(num);
  }

  const decimalParts = num.toString().split('.');
  if (decimalParts.length === 2) {
    fractionPoints += decimalParts[1].length;
  }

  return fractionPoints;
};

export const _multipleValidatorAjv = (schemaNum, testNum) => {
  if (schemaNum === 0 || !(typeof testNum === 'number' && isFinite(testNum)))
    return false;

  const testNumDecimals = _getFractionPoint(testNum);
  const schemaNumDecimals = _getFractionPoint(schemaNum);

  const maxDecimalNum = Math.max(testNumDecimals, schemaNumDecimals);
  const multiplier = Math.pow(10, maxDecimalNum);

  if (
    Math.round(testNum * multiplier) % Math.round(schemaNum * multiplier) !==
    0
  )
    return false;

  return true;
};

export const validacionExpToken = () => {
  const accessToken = window.localStorage.getItem('at');
  const tokenExpires = window.localStorage.getItem('te');

  if (!accessToken) {
    return false;
  }

  if (!tokenExpires) {
    return false;
  }
  const tokenExpireDecode = decodificacion(tokenExpires);

  const fecha = DateTime.local();
  const fechaTime = fecha.setZone('America/El_Salvador', {
    keepLocalTime: true,
  });

  return (
    tokenExpireDecode.substring(0, 19) > fechaTime.toString().substring(0, 19)
  );
};
