import { Provider } from 'react-redux';
import { MatxTheme } from './components';
import { SettingsProvider } from './contexts/SettingsContext';
import { Router } from './Router';
import { store } from './store/store';
import { SsoContextProvider } from './contexts/SsoContext/SsoContext';

const App = () => {
  return (
    <SettingsProvider>
      <MatxTheme>
        <SsoContextProvider>
          <Provider store={store}>
            <Router />
          </Provider>
        </SsoContextProvider>
      </MatxTheme>
    </SettingsProvider>
  );
};

export default App;
