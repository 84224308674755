import { createContext, useReducer } from 'react';
import { SSO_ACTIONS } from './ssoActions';

// * initial state
const initialState = {
  accessToken: null,
  accessTokenExpiresAt: null,
  isAuthenticated: false,
  user: null,
  redirection: false,
};

// * reducer
const reducer = (state, action) => {
  switch (action.type) {
    case SSO_ACTIONS.login: {
      const { user, accessToken, accessTokenExpiresAt, redirection } =
      action.payload;
      
      return {
        accessToken,
        accessTokenExpiresAt,
        isAuthenticated: true,
        user,
        redirection,
      };
    }

    case SSO_ACTIONS.logout: {
      return { ...initialState };
    }

    case SSO_ACTIONS.redirection: {
      const { redirection } = action.payload;
      return { redirection };
    }

    default:
      return { ...state };
  }
};

// * context
export const SsoContext = createContext();

// * provider
export const SsoContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SsoContext.Provider value={{ state, dispatch }}>
      {children}
    </SsoContext.Provider>
  );
};
