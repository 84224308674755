import { Box, Button, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const JustifyBox = styled(FlexBox)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: { maxWidth: 300 },
    maxWidth: 600,
    flexDirection: 'column',
    justifyContent: 'center',
}));

const IMG = styled('img')(() => ({
    width: '100%',
    marginBottom: '32px',
}));

const NotFoundRoot = styled(FlexBox)(() => ({
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh !important',
    padding: 30,
}));

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <NotFoundRoot>
            <JustifyBox>
                <IMG src='/assets/images/ilustrations/404.svg' />
                <Button
                    color='primary'
                    variant='contained'
                    sx={{ textTransform: 'capitalize' }}
                    onClick={() => navigate(-1)}
                >
                    Volver
                </Button>
            </JustifyBox>
        </NotFoundRoot>
    );
};

export default NotFound;
