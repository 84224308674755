import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Formulario = Loadable(lazy(() => import('./Formulario')));
const FacturacionElectronica = Loadable(
  lazy(() => import('./FacturacionElectronica'))
);
const FacturacionAnulacion = Loadable(
  lazy(() => import('./FacturacionAnulacion'))
);
const FacturacionContigencia = Loadable(
  lazy(() => import('./FacturacionContigencia'))
);

const FacturacionCancelacion = Loadable(
  lazy(() => import('./FacturacionCancelacion'))
);

const homeRoutes = [
  { path: '/lite/registro', element: <Formulario /> },
  { path: '/lite/dashboard', element: <FacturacionElectronica /> },
  { path: '/lite/invalidacion', element: <FacturacionAnulacion /> },
  { path: '/lite/contingencia', element: <FacturacionContigencia /> },
  { path: '/lite/cancelado', element: <FacturacionCancelacion /> },
];

export default homeRoutes;
