import React, { useEffect, useState } from 'react';
import NotFound from 'app/views/sessions/NotFound';
import HomeRoutes from './views/home/HomeRoutes';
import FacturacionRoutes from './views/facturacion/FacturacionRoutes';
import EzBillingRoutes from './views/ezBillingLite/EzBillingRoutes';
import EzBillingCloudRoutes from './views/ezBillingCloud/EzBillingCloudRoutes';
import ReportesRoutes from './views/Reportes/ReportesRoutes';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSso } from './contexts/SsoContext/useSso';
import { useUsertStore } from './store/hooks/useUserStore';
import useSettings from 'app/hooks/useSettings';
import { MatxLayouts, MatxSuspense } from 'app/components';
import { superUsuario } from './utils/constant';
import ConfiguracionRoutes from './views/configuracion/ConfiguracionRoutes';
import AnalyticsRoutes from './views/ezAnalytics/AnalyticsRoutes';
// import { TransactionsRouter } from './views/transactions/TransactionsRouter';
import DirectoryRouter from './views/directory/DirectoryRouter';

import { getTokenVerificationApi } from './services/sso.service';
import { validacionExpToken } from './utils/global';

export const Router = () => {
  const {
    isAuthenticated,
    user,
    authenticate,
    isAuthenticationVerified,
    redirection,
    logout,
  } = useSso();

  const { setUser } = useUsertStore();

  const { settings } = useSettings();
  const Layout = MatxLayouts[settings.activeLayout];

  const reVerifyAuth = async () => {
    const tiempoValido = validacionExpToken();

    if (!tiempoValido) {
      logout();
      return;
    }

    // Validacion de sesion activa
    const data = await getTokenVerificationApi();
    if (!data) {
      logout();
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      reVerifyAuth();
    }, 60000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    authenticate();
    setUser(user);
  }, []);

  if (!isAuthenticated && isAuthenticationVerified) {
    let SSO_LOGIN_URL = `${process.env.REACT_APP_SSO_LOGIN_PATH}`;
    if (redirection) {
      SSO_LOGIN_URL = `${process.env.REACT_APP_SSO_LOGIN_PATH}?continue=${window.location.href}`;
    }
    return (window.location.href = SSO_LOGIN_URL);
  }

  return (
    <MatxSuspense>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route path='/' element={HomeRoutes[0].element} />
          <Route path={HomeRoutes[0].path} element={HomeRoutes[0].element} />

          {(user?.ROLES.includes('FACTURACION_EZBILLING_PLUS') ||
            user?.ROLES.includes('SA')) && (
            <>
              {(user?.ROLES.includes('FACTURACION_TRANSACCIONAL') ||
                user?.ROLES.includes('FACTURACION_CONSULTAR') ||
                user?.ROLES.find((rol) => superUsuario?.includes(rol))) && (
                <>
                  <Route
                    path={FacturacionRoutes[0].path}
                    element={FacturacionRoutes[0].element}
                  />
                  <Route
                    path={FacturacionRoutes[1].path}
                    element={FacturacionRoutes[1].element}
                  />
                </>
              )}

              {(user?.ROLES.includes('FACTURACION_TRANSACCIONAL') ||
                user?.ROLES.find((rol) => superUsuario?.includes(rol))) && (
                <>
                  <Route
                    path={FacturacionRoutes[2].path}
                    element={FacturacionRoutes[2].element}
                  />
                  <Route
                    path={FacturacionRoutes[3].path}
                    element={FacturacionRoutes[3].element}
                  />
                  <Route
                    path={FacturacionRoutes[4].path}
                    element={FacturacionRoutes[4].element}
                  />
                  <Route
                    path={FacturacionRoutes[5].path}
                    element={FacturacionRoutes[5].element}
                  />
                  <Route
                    path={ReportesRoutes[0].path}
                    element={ReportesRoutes[0].element}
                  />
                </>
              )}
            </>
          )}
          {(user?.ROLES.includes('FACTURACION_EZBILLING_LITE') ||
            user?.ROLES.includes('SA')) && (
            <>
              <Route
                path={EzBillingRoutes[0].path}
                element={EzBillingRoutes[0].element}
              />
              <Route
                path={EzBillingRoutes[1].path}
                element={EzBillingRoutes[1].element}
              />
              <Route
                path={EzBillingRoutes[2].path}
                element={EzBillingRoutes[2].element}
              />
              <Route
                path={EzBillingRoutes[3].path}
                element={EzBillingRoutes[3].element}
              />
              <Route
                path={EzBillingRoutes[4].path}
                element={EzBillingRoutes[4].element}
              />
            </>
          )}

          {(user?.ROLES.includes('FACTURACION_EZBILLING_CLOUD') ||
            user?.ROLES.includes('SA')) && (
            <>
              <Route
                path={EzBillingCloudRoutes[0].path}
                element={EzBillingCloudRoutes[0].element}
              />
            </>
          )}

          {user?.ROLES.includes('SA') && (
            <>
              <Route
                path={ConfiguracionRoutes[0].path}
                element={ConfiguracionRoutes[0].element}
              />
            </>
          )}

          {(user?.ROLES.includes('FACTURACION_EZBILLING_ANALYTICS') ||
            user?.ROLES.includes('SA')) && (
            <>
              <Route
                path={AnalyticsRoutes[0].path}
                element={AnalyticsRoutes[0].element}
              />
            </>
          )}

          {(user?.ROLES.includes('DIRECTORIO_ADMIN') ||
            user?.ROLES.includes('SA')) && (
            <>
              <Route path='/directory/*' element={<DirectoryRouter />} />
            </>
          )}

          <Route path='/auth/login' element={<Navigate to={'/'} />} />

          <Route path='/*' element={<NotFound />} />
        </Route>
      </Routes>
    </MatxSuspense>
  );
};
