import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CancelIcon from '@mui/icons-material/Cancel';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SendIcon from '@mui/icons-material/Send';

export const topBarHeight = 64;
export const sideNavWidth = 260;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;

export const objTipoPrioridad = {
  A: {
    codigo: 'A',
    icon: <KeyboardArrowUpIcon className='text-danger' />,
    titulo: 'ALTA',
  },
  M: {
    codigo: 'M',
    icon: <DragHandleIcon className='text-warning' />,
    titulo: 'MEDIA',
  },
  B: {
    codigo: 'B',
    icon: <KeyboardArrowDownIcon className='text-primary' />,
    titulo: 'BAJA',
  },
};

export const tipoPrioridad = [
  {
    CODIGO: 'A',
    PRIORIDAD: 'ALTA',
  },
  {
    CODIGO: 'M',
    PRIORIDAD: 'MEDIA',
  },
  {
    CODIGO: 'B',
    PRIORIDAD: 'BAJA',
  },
];

export const objTipoFactura = {
  CRF: {
    DESCRIPCION: 'CREDITO FISCAL',
  },
  FCI: {
    DESCRIPCION: 'COMSUMIDOR FINAL',
  },
  FSE: {
    DESCRIPCION: 'SUJETO EXCLUIDO',
  },
  NTC: {
    DESCRIPCION: 'NOTA DE CREDITO',
  },
  FAE: {
    DESCRIPCION: 'EXPORTACION',
  },
};

export const ambiente = {
  production: {
    CODIGO: 'prod',
    DESCRIPCION: 'production',
  },
  development: {
    CODIGO: 'dev',
    DESCRIPCION: 'development',
  },
  prod: {
    CODIGO: 'prod',
    DESCRIPCION: 'production',
  },
  dev: {
    CODIGO: 'dev',
    DESCRIPCION: 'development',
  },
};

export const superUsuario = ['SUPER_USER', 'SA', 'FACTURACION_ADMIN'];
export const superUsuarioDev = ['SA', 'SUPER_USER_DEV'];

export const objTipoEstadoDocumento = {
  I: {
    codigo: 'I',
    icon: <ForwardToInboxIcon />,
    color: 'default',
    titulo: 'Ingresado',
    descripcion:
      'El documento ha ingresado a la base de datos y esta listo para ser enviado al MH',
  },
  C: {
    codigo: 'C',
    icon: <TroubleshootIcon />,
    color: 'warning',
    titulo: 'Contingencia',
    descripcion:
      'El documento no fue enviado al MH por un evento de contingencia',
  },
  A: {
    codigo: 'A',
    icon: <NotInterestedIcon />,
    color: 'primary',
    titulo: 'Invalidado',
    descripcion: 'El documento fue invalidado por el MH',
  },
  R: {
    codigo: 'R',
    icon: <CancelIcon />,
    color: 'error',
    titulo: 'Rechazado',
    descripcion:
      'El documento fue rechazado por el MH, ver detalle para revisión de observaciones',
  },
  P: {
    codigo: 'P',
    icon: <AssignmentTurnedInIcon />,
    color: 'info',
    titulo: 'Completado',
    descripcion:
      'El documento fue procesado por el MH y ha completado su ciclo de vida',
  },
  E: {
    codigo: 'E',
    icon: <SendIcon />,
    color: 'info',
    titulo: 'Enviando',
    descripcion: 'El documento esta siendo enviado automaticamente',
  },
  O: {
    codigo: 'O',
    icon: <ScheduleSendIcon />,
    color: 'primary',
    titulo: 'En cola',
    descripcion: 'El documento esta en la cola de envios automaticos',
  },
  M: {
    codigo: 'M',
    icon: <TroubleshootIcon />,
    color: 'warning',
    titulo: 'Contigencia MH',
    descripcion:
      'El documento no fue enviada al MH, no se obtuvo respuesta del MH',
  },
  X: {
    codigo: 'X',
    icon: <TroubleshootIcon />,
    color: 'warning',
    titulo: 'Cancelado',
    descripcion: 'El documento ha sido cancelado',
  },
};

export const objTipoFacturaElectronica = {
  '01': {
    CODGO: '01',
    DESCRIPCION: 'Factura',
  },
  '03': {
    CODGO: '03',
    DESCRIPCION: 'Comprobante de crédito fiscal',
  },
  '04': {
    CODGO: '04',
    DESCRIPCION: 'Nota de remisión',
  },
  '05': {
    CODGO: '05',
    DESCRIPCION: 'Nota de crédito',
  },
  '06': {
    CODGO: '06',
    DESCRIPCION: 'Nota de débito',
  },
  '07': {
    CODGO: '07',
    DESCRIPCION: 'Comprobante de retención',
  },
  '08': {
    CODGO: '08',
    DESCRIPCION: 'Comprobante de liquidación',
  },
  '09': {
    CODGO: '09',
    DESCRIPCION: 'Documento contable de liquidación',
  },
  11: {
    CODGO: '11 ',
    DESCRIPCION: 'Facturas de exportación',
  },
  14: {
    CODGO: '14 ',
    DESCRIPCION: 'Factura de sujeto excluido',
  },
  15: {
    CODGO: '15 ',
    DESCRIPCION: 'Comprobante de donación',
  },
};

export const objCodigoOperacion = {
  1: {
    CODGO: 1,
    DESCRIPCION: 'Contado',
  },
  2: {
    CODGO: 2,
    DESCRIPCION: 'A crédito',
  },
  3: {
    CODGO: 3,
    DESCRIPCION: 'Otro',
  },
};

export const objTipoPersona = {
  1: {
    CODGO: 1,
    DESCRIPCION: 'Persona Natural',
  },
  2: {
    CODGO: 2,
    DESCRIPCION: 'Persona Jurídica',
  },
};

export const tipoAnulacion = [
  {
    CODIGO: '1',
    DESCRIPCION:
      'Error en la Información del Documento Tributario Electrónico a invalidar.',
  },
  {
    CODIGO: '2',
    DESCRIPCION: 'Rescindir de la operación realizada.',
  },
  {
    CODIGO: '3',
    DESCRIPCION: 'Otro',
  },
];

export const objTipoAnulacion = {
  1: {
    CODGO: '1',
    DESCRIPCION:
      'Error en la Información del Documento Tributario Electrónico a invalidar.',
  },
  2: {
    CODGO: '2',
    DESCRIPCION: 'Rescindir de la operación realizada.',
  },
  3: {
    CODGO: '3',
    DESCRIPCION: 'Otro',
  },
};

export const tipoDocumento = [
  {
    CODIGO: '36',
    DESCRIPCION: 'NIT',
  },
  {
    CODIGO: '13',
    DESCRIPCION: 'DUI',
  },
  {
    CODIGO: '37',
    DESCRIPCION: 'Otro',
  },
  {
    CODIGO: '03',
    DESCRIPCION: 'Pasaporte',
  },
  {
    CODIGO: '02',
    DESCRIPCION: 'Carnet de Residente',
  },
];

export const objTipoGeneracionDoc = {
  1: {
    CODGO: '1',
    DESCRIPCION: 'Físico',
  },
  2: {
    CODGO: '2',
    DESCRIPCION: 'Electrónico',
  },
};

export const objTipoArticulo = {
  1: {
    CODGO: '1',
    DESCRIPCION: 'Bienes',
  },
  2: {
    CODGO: '2',
    DESCRIPCION: 'Servicios',
  },
  3: {
    CODGO: '3',
    DESCRIPCION:
      'Ambos (Bienes y Servicios, incluye los dos inherente a los Productos o servicios)',
  },
  4: {
    CODGO: '4',
    DESCRIPCION: 'Otros tributos por ítem',
  },
};

export const objUnidadMedida = {
  '01': {
    CODGO: '01',
    DESCRIPCION: 'Metro',
  },
  10: {
    CODGO: '10',
    DESCRIPCION: 'Hectárea',
  },
  36: {
    CODGO: '36',
    DESCRIPCION: 'Libra',
  },
  59: {
    CODGO: '59',
    DESCRIPCION: 'Unidad',
  },
  99: {
    CODGO: '99',
    DESCRIPCION: 'Otra',
  },
};

export const tipoContingencia = [
  {
    CODIGO: '1',
    DESCRIPCION: 'No disponibilidad de sistema del MH',
  },
  {
    CODIGO: '2',
    DESCRIPCION: 'No disponibilidad de sistema del emisor',
  },
  {
    CODIGO: '3',
    DESCRIPCION: 'Falla en el suministro de servicio de Internet del Emisor',
  },
  {
    CODIGO: '4',
    DESCRIPCION:
      'Falla en el suministro de servicio de energía eléctrica del emisor que impida la transmisión de los DTE',
  },
  {
    CODIGO: '5',
    DESCRIPCION:
      'Otro (deberá digitar un máximo de 500 caracteres explicando el motivo)',
  },
];

export const objTipoContingencia = {
  1: {
    CODIGO: '1',
    DESCRIPCION: 'No disponibilidad de sistema del MH',
  },
  2: {
    CODIGO: '2',
    DESCRIPCION: 'No disponibilidad de sistema del emisor',
  },
  3: {
    CODIGO: '3',
    DESCRIPCION: 'Falla en el suministro de servicio de Internet del Emisor',
  },
  4: {
    CODIGO: '4',
    DESCRIPCION:
      'Falla en el suministro de servicio de energía eléctrica del emisor que impida la transmisión de los DTE',
  },
  5: {
    CODIGO: '5',
    DESCRIPCION:
      'Otro (deberá digitar un máximo de 500 caracteres explicando el motivo)',
  },
};

export const objTipoEstablecimiento = {
  '01': {
    CODIGO: '01',
    DESCRIPCION: 'Sucursal / Agencia',
  },
  '02': {
    CODIGO: '02',
    DESCRIPCION: 'Casa matriz',
  },
  '04': {
    CODIGO: '04',
    DESCRIPCION: 'Bodega',
  },
  '07': {
    CODIGO: '07',
    DESCRIPCION: 'Predio y/o patio',
  },
  20: {
    CODIGO: '20',
    DESCRIPCION: 'Otro',
  },
};

export const validacionFactura = [
  {
    CODIGO: 'FE',
    VALOR: '01',
    DESCRIPCION: 'Factura',
  },
  {
    CODIGO: 'CCFE',
    VALOR: '03',
    DESCRIPCION: 'Comprobante de crédito fiscal',
  },
  {
    CODIGO: 'NRE',
    VALOR: '04',
    DESCRIPCION: 'Nota de remisión',
  },
  {
    CODIGO: 'NCE',
    VALOR: '05',
    DESCRIPCION: 'Nota de crédito',
  },
  {
    CODIGO: 'NDE',
    VALOR: '06',
    DESCRIPCION: 'Nota de débito',
  },
  {
    CODIGO: 'CRE',
    VALOR: '07',
    DESCRIPCION: 'Comprobante de retención',
  },
  {
    CODIGO: 'CLE',
    VALOR: '08',
    DESCRIPCION: 'Comprobante de liquidación',
  },
  {
    CODIGO: 'DCLE',
    VALOR: '09',
    DESCRIPCION: 'Documento contable de liquidación',
  },
  {
    CODIGO: 'FEXE',
    VALOR: '11',
    DESCRIPCION: 'Factura de exportación',
  },
  {
    CODIGO: 'FSEE',
    VALOR: '14',
    DESCRIPCION: 'Factura de sujeto excluido',
  },
  {
    CODIGO: 'CDE',
    VALOR: '15',
    DESCRIPCION: 'Comprobante de donación',
  },
];

export const receptorIdentificationTypeByDocument = {
  '01': 'NUMERO_DOCUMENTO',
  '03': 'NIT',
  '04': 'NUMERO_DOCUMENTO',
  '05': 'NIT',
  '06': 'NIT',
  '07': 'NUMERO_DOCUMENTO',
  '08': 'NIT',
  '09': 'NIT',
  11: 'NUMERO_DOCUMENTO',
  14: 'NUMERO_DOCUMENTO',
  15: 'NUMERO_DOCUMENTO',
};

export const claseContribuyente = [
  {
    CODIGO: 'G',
    VALOR: '01',
    DESCRIPCION: 'Gran Contribuyente',
  },
  {
    CODIGO: 'M',
    VALOR: '02',
    DESCRIPCION: 'Mediano Contribuyente',
  },
  {
    CODIGO: 'O',
    VALOR: '03',
    DESCRIPCION: 'Otro Contribuyente',
  },
];
