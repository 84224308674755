import { useDispatch, useSelector } from 'react-redux';
import {
  setUser as setUserAction,
  setUserPermissions as setUserPermissionsAction,
} from '../slices/userSlice';

export const useUsertStore = () => {
  const dispatch = useDispatch();
  const { user, userPermissions } = useSelector((state) => state.user);

  const setUser = (user) => {
    dispatch(setUserAction(user));
  };

  const setUserPermissions = (permissions) => {
    dispatch(setUserPermissionsAction(permissions));
  };

  return {
    // properties
    user,
    userPermissions,

    // methods
    setUser,
    setUserPermissions,
  };
};
