import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ButtonBase, styled } from '@mui/material';
import useSettings from 'app/hooks/useSettings';
import React, { Fragment } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Paragraph, Span } from '../Typography';
import MatxVerticalNavExpansionPanel from './MatxVerticalNavExpansionPanel';

const ListLabel = styled(Paragraph)(({ theme, mode }) => ({
  fontSize: '0.8rem',
  marginTop: '20px',
  marginLeft: '15px',
  marginBottom: '10px',
  textTransform: 'uppercase',
  display: mode === 'compact' && 'none',
  color: theme.palette.text.secondary,
  fontWeight: '600',
}));

const ExtAndIntCommon = {
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '2px',
  height: 36,
  whiteSpace: 'pre',
  marginBottom: '4px',
  textDecoration: 'none',
  justifyContent: 'space-between',
  transition: 'all 150ms ease-in',
  '&.compactNavItem': {
    overflow: 'hidden',
    justifyContent: 'center !important',
  },
  '& .icon': {
    fontSize: '18px',
    paddingLeft: '16px',
    paddingRight: '16px',
    verticalAlign: 'middle',
  },
};

const ExternalLink = styled('a')(({ theme }) => ({
  ...ExtAndIntCommon,
  '&:hover': {
    background:
      theme.palette.type === 'light'
        ? 'rgba(34, 42, 69, 0.08)'
        : 'rgba(255, 255, 255, 0.08)',
  },
  color: theme.palette.text.primary,
}));

const InternalLink = styled(Box)(({ theme }) => ({
  '& a': {
    ...ExtAndIntCommon,
    '&:hover': {
      background:
        theme.palette.type === 'light'
          ? 'rgba(34, 42, 69, 0.08)'
          : 'rgba(255, 255, 255, 0.08)',
    },
    color: theme.palette.text.primary,
  },
  '& .navItemActive': {
    backgroundColor:
      theme.palette.type === 'light'
        ? 'rgba(34, 42, 69, 0.08)'
        : 'rgba(255, 255, 255, 0.08)',
  },
}));

const StyledText = styled(Span)(({ mode }) => ({
  fontSize: '1rem',
  paddingLeft: '0.8rem',
  display: mode === 'compact' && 'none',
  fontFamily: 'Quicksand',
  fontWeight: '400',
}));

const BulletIcon = styled('div')(() => ({
  marginLeft: '1.8rem',
  overflow: 'hidden',
  borderRadius: '300px',
}));

const BadgeValue = styled('div')(() => ({
  padding: '1px 8px',
  overflow: 'hidden',
  borderRadius: '300px',
}));

const IconClass = styled('span')(() => ({
  paddingLeft: '10px',
}));

const MatxVerticalNav = ({ items }) => {
  const { settings } = useSettings();
  const { mode } = settings.layout1Settings.leftSidebar;
  const { pathname } = useLocation();

  const renderLevels = (data) => {
    return data.map((item, index) => {
      if (item.type === 'label')
        return (
          <ListLabel key={index} mode={mode} className='sidenavHoverShow'>
            {item.label}
          </ListLabel>
        );

      if (item.children) {
        return (
          <MatxVerticalNavExpansionPanel mode={mode} item={item} key={index}>
            {renderLevels(item.children)}
          </MatxVerticalNavExpansionPanel>
        );
      } else if (item.type === 'extLink') {
        return (
          <ExternalLink
            key={index}
            href={item.path}
            className={`${mode === 'compact' && 'compactNavItem'}`}
            rel='noopener noreferrer'
            target='_blank'
          >
            <ButtonBase key={item.name} name='child' sx={{ width: '100%' }}>
              {item.icon && (
                <IconClass>
                  <FontAwesomeIcon icon={item.icon} size='lg' />
                </IconClass>
              )}
              <StyledText mode={mode} className='sidenavHoverShow'>
                {item.name}
              </StyledText>
              <Box mx='auto'></Box>
              {item.badge && <BadgeValue>{item.badge.value}</BadgeValue>}
            </ButtonBase>
          </ExternalLink>
        );
      } else {
        return (
          <InternalLink key={index}>
            <NavLink
              to={item.path}
              className={() =>
                pathname === item.path
                  ? `navItemActive ${mode === 'compact' && 'compactNavItem'}`
                  : `${mode === 'compact' && 'compactNavItem'}`
              }
            >
              <ButtonBase key={item.name} name='child' sx={{ width: '100%' }}>
                {item?.icon ? (
                  <IconClass>
                    <FontAwesomeIcon
                      icon={item.icon}
                      fixedWidth={true}
                      style={{ fontSize: '1rem' }}
                    />
                  </IconClass>
                ) : (
                  <Fragment>
                    <BulletIcon
                      className={`nav-bullet`}
                      sx={{ display: mode === 'compact' && 'none' }}
                    />
                  </Fragment>
                )}

                <StyledText mode={mode} className='sidenavHoverShow'>
                  {item.name}
                </StyledText>

                <Box mx='auto' />

                {item.badge && (
                  <BadgeValue className='sidenavHoverShow'>
                    {item.badge.value}
                  </BadgeValue>
                )}
              </ButtonBase>
            </NavLink>
          </InternalLink>
        );
      }
    });
  };

  return <div className='navigation'>{renderLevels(items)}</div>;
};

export default React.memo(MatxVerticalNav);
