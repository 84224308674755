import { encriptacion } from 'app/utils/global';
import axios, { AxiosError } from 'axios';

// * read required environment variables
const SSO_API_URL = process.env.REACT_APP_SSO_API_PATH;

// throw error if SSO_API_URL is not defined in environment variables
if (!SSO_API_URL) {
  throw new Error(
    'Error 404: SSO_API_URL environment variable is not defined. Define this environment variable and rerun the build or restart the development server.'
  );
}

// * axios instance creation
const ssoApi = axios.create({
  baseURL: SSO_API_URL,
});

// * token verification handle errors
const handleError = (error) => {
  if (error instanceof AxiosError) {
    if (error.response.status === 401) {
      console.clear();
    } else if (error.response.status === 404) {
      throw new Error('Error 404: Error connecting with authentication api.');
    } else if (error.response.status === 500) {
      throw new Error('Error 500: Internal server error in authentication api');
    } else {
      console.error(error);
    }
  } else {
    console.error(error);
  }
};

// * get token param from the url (both: hashRouter or browserRouter)
const getTokenFromURL = () => {
  const url = window.location.href;
  const parsedURL = new URL(url);
  const params = new URLSearchParams(
    parsedURL.search || parsedURL.hash.substr(parsedURL.hash.indexOf('?') + 1)
  );
  return params.get('token');
};

// * remove token from url
function removeTokenFromURL() {
  const currentURL = window.location.href;
  const hashIndex = currentURL.indexOf('#');

  let newURL = currentURL.replace(
    /(\?|&)token=[^&#]+(&?)/,
    function (match, p1, p2) {
      return p2 ? p1 : '';
    }
  );

  if (hashIndex !== -1) {
    const hashPart = currentURL.substr(hashIndex);
    const newHash = hashPart.replace(
      /(\?|&)token=[^&#]+(&?)/,
      function (match, p1, p2) {
        return p2 ? p1 : '';
      }
    );

    newURL = newURL.substr(0, hashIndex) + newHash;
  }

  window.history.replaceState(null, '', newURL);
}

// * GET token verification function
// * if localStorage "at" or url query param "token" are present and are valid retun the token, else returns null
export const getTokenVerification = async () => {
  //* verify url parameter token
  const urlToken = getTokenFromURL();

  if (urlToken) {
    try {
      const u = localStorage.getItem('u') || null;
      const response = await ssoApi.get('/v1/oauth/authenticate', {
        headers: {
          Authorization: `Bearer ${urlToken}`,
          User: u,
        },
      });

      if (response.status === 200) {
        // * authenticated with url token
        const data = response.data;
        const rt = data?.oauth2?.refreshToken;
        const c = data?.oauth2?.user?.c;
        const u = data?.oauth2?.user?.us;
        const te = data?.oauth2?.accessTokenExpiresAt;

        localStorage.setItem('at', urlToken);
        localStorage.setItem('rt', rt);
        localStorage.setItem('c', c);
        localStorage.setItem('u', u);
        localStorage.setItem('te', encriptacion(te));

        removeTokenFromURL();
        return { data };
      }
    } catch (error) {
      handleError(error);
    }
  }

  //* verify localstorage token
  const localStorageToken = localStorage.getItem('at');

  if (localStorageToken) {
    try {
      const u = localStorage.getItem('u') || null;
      const response = await ssoApi.get('/v1/oauth/authenticate', {
        headers: {
          Authorization: `Bearer ${localStorageToken}`,
          User: u,
        },
      });

      if (response.status === 200) {
        // * authenticated with localstorage token
        const data = response.data;
        const rt = data?.oauth2?.refreshToken;
        localStorage.setItem('rt', rt);
        removeTokenFromURL();
        return { data };
      }
    } catch (error) {
      handleError(error);
    }
  }

  // * Not server errors and not authenticated
  // * Returns null
  return null;
};

export const getTokenVerificationApi = async () => {
  //* verify localstorage token
  const localStorageToken = localStorage.getItem('at');

  if (localStorageToken) {
    try {
      const u = localStorage.getItem('u') || null;
      const response = await ssoApi.get('/v1/oauth/authenticate/api', {
        headers: {
          Authorization: `Bearer ${localStorageToken}`,
          User: u,
        },
      });

      if (response.status === 200) {
        // * authenticated with localstorage token
        const data = response.data;
        removeTokenFromURL();
        return { data };
      }
    } catch (error) {
      handleError(error);
    }
  }

  // * Not server errors and not authenticated
  // * Returns null
  return null;
};
// * POST logout
// * if can logout return true, else return false
export const postLogout = async () => {
  try {
    const token = localStorage.getItem('at') || '';
    const refreshToken = localStorage.getItem('rt') || '';

    const formData = new FormData();
    formData.append('client_secret', 'secret');
    formData.append('client_id', 'application');
    formData.append('grant_type', 'refresh_token');
    formData.append('refresh_token', refreshToken);

    const response = ssoApi.post(
      '/v1/oauth/logout',
      new URLSearchParams(formData).toString(),
      {
        haders: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    if (response.status === 200) {
      window.localStorage.clear();
      return true;
    }
  } catch (err) {
    handleError();
    return false;
  }
};
