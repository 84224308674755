import { useContext, useState } from 'react';

import { SsoContext } from './SsoContext';
import { SSO_ACTIONS } from './ssoActions';

import { getTokenVerification, postLogout } from 'app/services/sso.service';
import { decodificacion, decodificacionObject } from 'app/utils/global';

export const useSso = () => {
  const { state, dispatch } = useContext(SsoContext);

  const [isAuthenticationVerified, setIsAuthenticationVerified] =
    useState(false);

  const {
    isAuthenticated,
    user,
    accessToken,
    accessTokenExpiration,
    redirection,
  } = state;




  const decodificarData = (data) => {
    const roles = data.ROLES;
    const apps = data.APP;
    const userRoles = [];
    const userApp = [];

    for (let i = 0; i < roles.length; i++) {
      userRoles.push(decodificacion(roles[i]));
    }

    for (let j = 0; j < apps.length; j++) {
      userApp.push(decodificacionObject(apps[j]));
    }

    const userDescrypt = {
      USUARIO_NOMBRE: decodificacion(data.USUARIO_NOMBRE),
      USERNAME: decodificacion(data.USERNAME),
      EMAIL: decodificacion(data.EMAIL),
      COUNTRY: decodificacion(data.COUNTRY),
      EMPRESA: decodificacion(data.EMPRESA),
      URL: decodificacion(data.URL),
      EMPLEADO: decodificacion(data.EMPLEADO),
      ROLES: userRoles,
      APP: userApp,
    };

    return userDescrypt;
  };

  const authenticate = async () => {
    const data = await getTokenVerification();

    if (data) {
      const { user, r, accessToken, accessTokenExpiresAt, a } =
        data.data.oauth2;

      const userFormat = {
        ID: user.id,
        USERNAME: user.us,
        USUARIO_NOMBRE: user.un,
        EMAIL: user.e,
        COUNTRY: user.c,
        ROLES: r,
        APP: a,
        EMPRESA: user.em,
        URL: user.u,
        EMPLEADO: user.ep,
      };

      const userData = decodificarData(userFormat);

      dispatch({
        type: SSO_ACTIONS.login,
        payload: {
          user: userData,
          accessToken,
          accessTokenExpiresAt,
          redirection: false,
        },
      });
    } else {
      dispatch({
        type: SSO_ACTIONS.redirection,
        payload: {
          redirection: true,
        },
      });
    }

    setIsAuthenticationVerified(true);
  };

  const logout = async () => {
    await postLogout();
    window.localStorage.clear();
    dispatch({
      type: SSO_ACTIONS.logout,
    });
  };

  const close = () => {
    dispatch({
      type: SSO_ACTIONS.logout,
    });
  };

  return {
    // properties
    isAuthenticated,
    isAuthenticationVerified,
    user,
    accessToken,
    accessTokenExpiration,
    state,
    redirection,

    // methods
    authenticate,
    logout,
    close,
  };
};
