import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const FacturacionInterna = Loadable(lazy(() => import('./FacturacionInterna')));
const FacturacionElectronica = Loadable(
  lazy(() => import('./FacturacionElectronica'))
);
const FacturacionAnulacion = Loadable(
  lazy(() => import('./FacturacionAnulacion'))
);
const FacturacionContigencia = Loadable(
  lazy(() => import('./FacturacionContigencia'))
);
const FacturacionCancelacion = Loadable(
  lazy(() => import('./FacturacionCancelacion'))
);
const FacturacionElectronicaExport = Loadable(
  lazy(() => import('./FacturacionElectronicaExport'))
);

const companyRoutes = [
  { path: '/interno', element: <FacturacionInterna /> },
  { path: '/dashboard', element: <FacturacionElectronica /> },
  { path: '/importar', element: <FacturacionElectronicaExport /> },
  { path: '/invalidacion', element: <FacturacionAnulacion /> },
  { path: '/contingencia', element: <FacturacionContigencia /> },
  { path: '/cancelado', element: <FacturacionCancelacion /> },
];

export default companyRoutes;
