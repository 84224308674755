export const ResponseHttp = (status) => {
  let mensaje = '';

  if (status === 0) {
    return (mensaje = 'No hay respuesta del servidor y la base de datos');
  }

  /* ERRORES DEL CLIENTE */
  if (status === 400) {
    return (mensaje = 'Solicitud invalida');
  }

  if (status === 401 || status === 402 || status === 403) {
    return (mensaje = 'No se cuenta con la autenticacion para la solicitud');
  }

  if (status === 403) {
    return (mensaje = 'No se cuenta con los permisos para la solicitud');
  }

  if (status === 404) {
    return (mensaje = 'No se encontro el contenido solicitado');
  }

  if (status === 408) {
    return (mensaje = 'Tiempo de espera de solicitud finalizado');
  }

  /* ERRORES DEL SERVIDOR */
  if (status === 500) {
    return (mensaje = 'Error de conexion con el servidor');
  }

  if (status === 502) {
    return (mensaje = 'Error al obtener repuesta del servidor');
  }

  if (status === 503) {
    return (mensaje = 'Peticion no disponible en el servidor');
  }

  return mensaje;
};

export const ResponseCode = (code) =>{
  let mensaje = '';

  if (code === 'ERR_NETWORK') {
    return (mensaje = 'Error de conexion con el servidor');
  }

  /* ERRORES DEL CLIENTE */
  if (code === 'ERR_BAD_REQUEST') {
    return (mensaje = 'Credenciales icorrectas');
  }
  return mensaje;
}