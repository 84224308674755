const { createSlice } = require('@reduxjs/toolkit');

export const userSlice = createSlice({
  name: 'userSlice',
  initialState: {
    user: null,
    userPermissions: [],
  },
  reducers: {
    setUser: (state, action) => {
      const user = action.payload;
      state.user = user;
    },
    setUserPermissions: (state, action) => {
      const userPermissions = action.payload;
      state.userPermissions = userPermissions;
    },
  },
});

export const { setUser, setUserPermissions } = userSlice.actions;
