import { configureStore } from '@reduxjs/toolkit';

import { licenseSlice } from './slices/licenseSlice';
import { userSlice } from './slices/userSlice';

export const store = configureStore({
  reducer: {
    license: licenseSlice.reducer,
    user: userSlice.reducer,
  },
});
