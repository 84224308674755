import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const TipoReporteContable = Loadable(lazy(() => import('./TipoReporteContable')));

const companyRoutes = [{
    path: '/reportes', element: <TipoReporteContable />
}];

export default companyRoutes;
