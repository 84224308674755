/* react */
import { lazy } from 'react';
/* react router */
import { Route, Routes } from 'react-router-dom';
/* components */
import Loadable from 'app/components/Loadable';
import NotFound from '../auth/NotFound';

const DirectoryRouter = () => {
    const DirectoryContacts = Loadable(
        lazy(() => import('app/views/directory/pages/DirectoryContacts'))
    );

    const DirectoryClients = Loadable(
        lazy(() => import('app/views/directory/pages/DirectoryClients'))
    );

    return (
        <Routes>
            <Route path='/contact' element={<DirectoryContacts />} />
            <Route path='/client' element={<DirectoryClients />} />
            <Route path='/*' element={<NotFound />} />
        </Routes>
    );
};

export default DirectoryRouter;
