const { createSlice } = require('@reduxjs/toolkit');

export const licenseSlice = createSlice({
  name: 'licenseSlice',
  initialState: {
    licenseName: null,
    licensePermissions: [],
  },
  reducers: {
    setLicense: (state, action) => {
      const { licenseName, licensePermissions } = action.payload;
      state.licenseName = licenseName;
      state.licensePermissions = licensePermissions;
    },
    setLicenseName: (state, action) => {
      state.licenseName = action.payload;
    },
    setLicensePermissions: (state, action) => {
      state.licensePermissions = action.payload;
    },
  },
});

export const { setLicense, setLicenseName, setLicensePermissions } =
  licenseSlice.actions;
