import { red } from '@mui/material/colors';
import { components } from './components';

const themeOptions = {
  typography: {
    fontSize: 14,
    fontWeight: '400',
    fontFamily:
      '"Quicksand", "Poppins", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, san-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    body1: {
      fontSize: '14px',
      fontFamily:
        '"Quicksand", "Poppins", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, san-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      fontWeight: '400',
    },
  },
  status: { danger: red[500] },
  components: { ...components },
};

export default themeOptions;
